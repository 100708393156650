import React from 'react'; 
import ReactHtmlParser from 'react-html-parser';

export default function FieldInlineVideo(props) {
	
	const fieldId = props.data.fieldId;
	const data = props.data.dataLink;
	
	return (<div>
		{ ReactHtmlParser(data) }
	</div>);
}
