import React, { Component } from 'react'; 
import $ from 'jquery';
import {DebounceInput} from 'react-debounce-input';

export default class Q9 extends Component {
	constructor(props) {
        super(props);
        this.state={
            q9:'',
        }
        this.shoot = this.shoot.bind(this); 
    }
    
    shoot(event) {
        this.setState({q9:event.target.value})
        this.props.func(this.props.data.fieldId,event.target.value);
    }
    render(){
        const {data} = this.props;
        let id = data.fieldId;
		let kioskResponse = data.kioskResponse;
		//console.log('kioskResponse->',kioskResponse);
        return (
            <DebounceInput element="textarea" debounceTimeout={4000} className={'form-control res_'+id } rows="15" id="comment" value={kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.q9} onChange={this.shoot}/>
        );
    }
}