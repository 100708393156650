import React, { Component } from 'react'; 
import Box from '@mui/material/Box';
import SecondsTohhmmss from './SecondsTohhmmss'
import $ from 'jquery';
import PropTypes from 'prop-types';

let offset = null, interval = null

export default class TimerHtml extends Component {
	static get propTypes () {
    return {
      options: PropTypes.object
    }
  }

  constructor(props) {
    super(props)
    this.state = { clock: 0, time: '' }
    
  }

  componentWillUnmount() {
    this.pause()
  }

  pause() {
    if (interval) {
      clearInterval(interval)
      interval = null
    }
  }
  
  play() {
    const OPTIONS = { prefix: 'seconds elapsed!', delay: 100}
    if (!interval) {
      offset = Date.now()
      interval = setInterval(this.update.bind(this), OPTIONS.delay)
    }
  }

  reset() {
    let clockReset = 0
    this.setState({clock: clockReset })
    let time = SecondsTohhmmss(clockReset / 1000)
    this.setState({time: time })
  }
  
  stop(){
    if (interval) {
        this.props.func(this.props.data.fieldId,this.state.time);
        clearInterval(interval)
        interval = null
    }
  }
  
  update() {
    let clock = this.state.clock
    clock += this.calculateOffset()
    this.setState({clock: clock })
    let time = SecondsTohhmmss(clock / 1000)
    this.setState({time: time })
  }

  calculateOffset() {
    let now = Date.now()
    let newOffset = now - offset
    offset = now
    return newOffset
  }

  render() {
    const {data} = this.props;
	let id = data.fieldId;
	let kioskResponse = data.kioskResponse;
	
	if(kioskResponse.size > 0){
		var multiRes = kioskResponse.get(id);
		if(multiRes !== undefined){
			var newArray = multiRes
		}
	}
	
    const timerStyle = {
      margin: "0px",
    };

    const buttonStyle = {
      background: "#fff",
      color: "#666",
      border: "1px solid #ddd",
      marginRight: "5px",
      padding: "10px",
      fontWeight: "200"
    };

    const secondsStyles = {
      fontSize: "200%",
      fontWeight: "200",
      lineHeight: "1.5",
      margin: "0",
      color: "#666"
    };

    return (
      <div style={timerStyle} className="react-timer">
        <h3 style={secondsStyles} className="seconds"> {this.state.time ? this.state.time : newArray} {this.props.prefix}</h3>
        <br />
        <button onClick={this.reset.bind(this)} style={buttonStyle} >reset</button>
        <button onClick={this.play.bind(this)} style={buttonStyle} >start</button>
        <button onClick={this.pause.bind(this)} style={buttonStyle} >pause</button>
        <button onClick={this.stop.bind(this)} style={buttonStyle} >stop/save</button>
      </div>
    )
  }
}

/* import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export function TimerHtml(rowData) {
	let id = rowData.fieldId;
	
	const timerStyle = {
      margin: "0px",
    };

    const buttonStyle = {
      background: "#fff",
      color: "#666",
      border: "1px solid #ddd",
      marginRight: "5px",
      padding: "10px",
      fontWeight: "200"
    };

    const secondsStyles = {
      fontSize: "200%",
      fontWeight: "200",
      lineHeight: "1.5",
      margin: "0",
      color: "#666"
    };
	return (
		<Box className="mui-slider" width={300}>
			<div style={timerStyle} className="react-timer">
				<h3 style={secondsStyles} className="seconds">00:00:02.12</h3>
				<br />
				<button style={buttonStyle} >reset</button>
				<button style={buttonStyle} >start</button>
				<button style={buttonStyle} >pause</button>
				<button style={buttonStyle} >stop/save</button>
			</div>
		</Box>
	);
}
 */