import React, { Fragment, useState } from 'react';
import $ from 'jquery';
import { makeStyles }   from '@material-ui/core/styles';
import TextField        from '@material-ui/core/TextField';
import Button           from '@material-ui/core/Button';
import Grid             from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import axios  from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: '10px 0px'
    },
    fileGrid: {
        border: '1px solid #c5c5c5',
        margin: '10px -10px',
    },
    inputFile: {
        marginTop: '20px',
    },
    uploadButton: {
        borderRadius: '20px',
    },
    spinner: {
        marginLeft: '15px'
    }
}));

export default function ApiVideoUpload(props) {
	const fieldId = props.data.fieldId;
	const analzeVideo = props.data.analzeVideo;
	const pdfReport = props.data.pdfReport;
	const preView = props.data.preView;
	const classes = useStyles();
    //const [title, setTitle]             = useState('');
    //const [description, setDescription] = useState('');
    const [fileName, setFileName]       = useState('');
    const [videoFile, setVideoFile]     = useState('');
    const [embedCode, setEmbedCode]         = useState('');
    const [showSpinner, setShowSpinner]     = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(true);
    const [showUploadButton, setShowUploadButton] = useState(false);
	const [showEmbedCode, setShowEmbedCode] = useState(false);
	const [showPdf, setShowPdf] = useState(false);
	const [showMain, setShowMain] = useState(false);
	const [dataField, setDataField] = useState('');
	
	const handleChange = (files) => {
        setFileName(files[0].name);
        setVideoFile(files[0]);
		setShowPdf(false);
		setShowMain(false);
    }

	const showUpload = () => {
		if(preView){
			return false;
		}
		setUploadSuccess(false);
		setShowUploadButton(true);
	}
	
	const deleteUpload = () => {
		$( "#ans"+fieldId ).html('');
		setDataField('');
		setShowPdf(false);
	}
	
	const analyzeUpload = () => {
		let embedCode = '<iframe src="'+analzeVideo+'" width="620" height="310" frameborder="0" allowfullscreen></iframe>';
		$( "#ans"+fieldId ).html(embedCode);
		setShowPdf(false);
		setShowMain(true);
	}
	
	const reportUpload = () => {
		let embedCode = '<embed width="620" height="310" src='+pdfReport+' />';
		$( "#ans"+fieldId ).html(embedCode);
		setShowPdf(true);
		setShowMain(true);
	}
	
	const mainVideo = () => {
		$( "#ans"+fieldId ).html(dataField);
		setShowMain(false);
	}
	
	const uploadSubmit = () => {
		/* let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		let blobURL = URL.createObjectURL(videoFile);
		let embedCode = '<iframe src="'+blobURL+'" width="560" height="315" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Inline Video"></iframe>'
		let videoData1 = embedCode+'<br><b>Name:</b> '+title+'<br><b>Description:</b> '+description+'<br><b>Date:</b> '+date;
		let videoData1 = embedCode;
		$( "#ans"+fieldId ).html(videoData1);
		
		if (blobURL) {
            setUploadSuccess(true);
			setShowEmbedCode(true);
			setShowUploadButton(false);
			setShowSpinner(false);
			setFileName('');
			setVideoFile('');
			setDataField(embedCode);
		} */
		setShowSpinner(true);
		let url = 'https://opie.staging.viewlab.cloud/api/upload/123';
		let apiKey = '8E803467-63C3-4A04-93A0-6EF0D8DBF593';
		axios({
            method: 'Get',
            url: url,
            headers: {
                'x-api-key': apiKey
            }
        })
        .then(response => {
			let data = response.data;
			let uploadUrl = data.uploadUrl;
			let encodeUrl = data.encodeUrl;
			let file = videoFile;
			
			if (uploadUrl && encodeUrl && file) {
				axios({
					method: 'PUT',
					url: uploadUrl,
					headers: {
						'Content-Type': file.type,
						'x-ms-blob-type': 'BlockBlob'
					},
					data: file
				}).then(response => {
					getEncodeUrl(encodeUrl);
				}).catch(error => {
					console.log('error::', error);
				})
			}
        })
        .catch(error => {
            console.log('error::', error);
        }) 
    }
	
	const getEncodeUrl = (encodeUrl) => {
		axios({
			method: 'PUT',
			url: encodeUrl
		}).then(response => {
			let data = response.data;
			if(data.encoded == true){
				if(data.mediaUrl){
					this.props.func(this.props.data.fieldId,data.mediaUrl);
					let embedCode = '<video src="'+data.mediaUrl+'" width="620" poster="'+data.posterUrl+'" controls></video>';
					$( "#ans"+fieldId ).html(embedCode);
					setUploadSuccess(true);
					setShowEmbedCode(true);
					setShowUploadButton(false);
					setShowSpinner(false);
					setDataField(embedCode);
				}
			}else{
				setTimeout(function(){
					getEncodeUrl(encodeUrl);
				}, 3000);	
			}
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	return (
	<Fragment>
		<div id={'ans'+fieldId} className="api-video"></div>
		<form className={classes.root} noValidate autoComplete="off">
			{ !showUploadButton && <div><Button className="api-upload-btn" id={"uploadbtn-"+fieldId} variant="contained" color="primary" onClick={() =>showUpload()}>{dataField ? 'Replace Video' : 'Upload Video'}</Button>&nbsp;
			{dataField ? 
			<div className="btn-analze-report">
			<button id={"deletebtn-"+fieldId} type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={() =>deleteUpload()}><i className="fa fa-trash"></i></button>
			
			<button type="button" className="btn btn-primary btn-analze" onClick={() =>analyzeUpload()}>Analyze Video</button>
			<button type="button" className="btn btn-primary btn-report" onClick={() =>reportUpload()}>Get Report</button>
			{showPdf ?
			<a className="btn code-dialog btn-report-pdf" href={pdfReport} target="_blank"><i className="fa fa-file-pdf-o" aria-hidden="true"></i></a>
			:null}
			{showMain ?
			<button type="button" className="btn btn-primary btn-main-video" onClick={() =>mainVideo()}>Original Video</button>
			:null}
			</div>
			:null}</div>
			}
			{ !uploadSuccess && <Grid container spacing={3} className={classes.fileGrid}>
				<Grid item xs={12} className={classes.inputFile}>
					<Button
						variant="contained"
						component="label"
						color="secondary"
						className={classes.uploadButton}
						>
						<AddIcon /> Select Video File
						<input
							type="file"
							name="video_file" 
							style={{ display: "none" }}
							onChange={ (e) => handleChange(e.target.files) }
						/>
					</Button>
					{ fileName && (<Box component="span" display="block">{fileName}</Box>) }
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" onClick={() =>uploadSubmit()}>Upload { showSpinner && <CircularProgress disableShrink size="20px" className={classes.spinner} /> }</Button>
				</Grid>
			</Grid> }
		</form>
	</Fragment>
	);
}
