import React, { Component, PropTypes } from 'react'
import 'bootstrap';
import $ from 'jquery';
import config from "../../config";
import { gapi } from 'gapi-script';
import { Form,Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { generatehtmlfrom } from "../../Helpers/Common";
import Checkbox from '@mui/material/Checkbox';

import FieldQ13 from "../Fields/Q13.js";
import Launchpad from "../Fields/Launchpad";
//import FieldInlineImage from "../Fields/InlineImage.js";
import FieldInlineVideo from "../Fields/InlineVideo.js";
import FieldPdfApi from "../Fields/PdfApi.js";
import FieldAssessment from "../Fields/Assessment.js";
import FieldSubjective from "../Fields/Subjective.js";
import FieldCalculation from "../Fields/Calculation.js";
import Checklist from "../Fields/Checklist";

import { noteHtml,noteModal,attentHTML} from "../Modal";

export default class PdfData extends Component {
    constructor(props){
        super(props)
        this.state = {
            checkedNeeds:new Map(),
            checkedApprove:new Map(),
        }
    }
	
    classTypes(comment){
        let className = 'question';
		let notInclude = '';
        if(comment[5].toLowerCase() == 'parent header'){
            className = 'parent-header';
        }else if(comment[5].toLowerCase() == 'child header 1'){
			className = 'header-1 ';
		}else if(comment[5].toLowerCase() == 'child header 2'){
			className = 'header-2 ';
		}else if(comment[5].toLowerCase() == 'child header 3'){
			className = 'header-3 ';
		}
		
		/* if(comment[5].toLowerCase() == 'boxbegin' || comment[5].toLowerCase() == 'boxend'){
			className = ' '+comment[5]+'-div ';
		} */
		
		if(comment[5] !== 'Parent Header' && comment[14] == 'N'){
            className = className+' not-include';
        }
		let needNote = this.props.needsNote.get(comment[0]);
		if(needNote){
			className = className+' attention';
		}
		
		if(!this.props.showResponseData && !this.props.delegateFilter && comment[14] == 'Y' && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
			$('#'+comment[0]).removeClass("not-include");
		}
		
		if(this.props.delegateFilter && comment[14] == 'Y'){
			let delegateFilter = this.props.delegateFilter;
			let uArray = this.props.delegateUsers.get(comment[0]);
			
			if(uArray.indexOf(delegateFilter) == -1 && (comment[5].toLowerCase() != 'parent header' && comment[5].toLowerCase() != 'child header 1' && comment[5].toLowerCase() != 'child header 2' && comment[5].toLowerCase() != 'child header 3')){
				className = className+' not-include';
			}else if(delegateFilter != '' && delegateFilter != 'CPO' && comment[37] == 'N'){
				className = className+' not-include';
			}
			
			setTimeout(function(){
				if(comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3'){
					$('#'+comment[0]).removeClass("not-include");
				}
			
				if($('#'+comment[0]).find('.question').not(".not-include").length < 1 && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
					$('#'+comment[0]).addClass("not-include");	
				}
			}, 2000);
		}
		
		if(this.props.showResponseData){
			setTimeout(function(){
				if(($('#'+comment[0]).find('.question').not(".not-include").length < 1 || $('#'+comment[0]).find('.question').not(".not-response").length < 1) && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
					$('#'+comment[0]).addClass("not-include");	
				}
			}, 2000);
		}
		
		setTimeout(function(){
			$( ".not-include" ).remove();
		}, 3000);
		
		return className;
    }

	getQueType(type,id,res){
		
        switch(type){
            case 'sig':
                return <i className="fas fa-edit" onClick={()=>this.showSign2(type,id)}></i>
            break;
            case 'q10':
                return <Checkbox id={'type'+id} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} onClick={()=>this.showSign(type,id)} checked={res ? true : false}/>
            break;
            default:
                return null
        }
    }
	
	showSign=(type,id)=>{
		if($("#type"+id).prop("checked") == true){
			$('#'+type+id).css('display','block');
		}else if($("#type"+id).prop("checked") == false){
			$('#'+type+id).css('display','none');
		}
    }
	
	showSign2=(type,id)=>{
        $('#'+type+id).css('display','block')
    }
	
	subQuestions = (id,value) => {
		this.props.getResponseValue(id,value);
	}
	
	render() {
        const {sheetData, getPlaceholder, queId, comment, outputText, apiPdfs, kioskResponse, dataMode, needsNote} = this.props;
		//console.log('kioskResponse->',kioskResponse);
		return (
          <div id={queId} className={queId ? 'inner-'+ comment[0] +' childMain col-11 offset-1 '+queId+' collapse show' : 'commentss'} >
            {sheetData.map((comment,i) =>
              <div id={comment[0]} data-pos={comment[10]} key={i}  className={this.classTypes(comment)}>
                {(() => {
					
					let question = comment[9] ? comment[9] : comment[4];
					/* if(outputText){
						question = comment[9] ? comment[9] : comment[4];
					} */
					let answer = generatehtmlfrom(comment,this.subQuestions,this.props);
					let res = kioskResponse.get(comment[0]);
					let that = this;
					switch (comment[5].toLowerCase()) {
                        case 'parent header':
                            return <h3 id ={'Parent'+comment[1].toLowerCase()} className={'headers-'+comment[0]}>{ question } </h3>;
                        case 'child header 1':
                            return <div className="child-headers">
                                        <h4 id ={'Parent'+comment[1].toLowerCase()} className={'headers-'+comment[0]}>{question}</h4>
                                    </div>
                        case 'child header 2':
                            return <div className="child-headers">
                                    <h5 id ={'Parent'+comment[1].toLowerCase()} className={'headers-'+comment[0]}>{question}</h5>       
                            </div>;
                        case 'child header 3':
                            return <div className="child-headers">
								<h6 id ={'Parent'+comment[1].toLowerCase()} className={'headers-'+comment[0]}>{question}</h6>    
                            </div>;
						
                        case 'title':
						return (
						<div className="full-width default-div">
							<div className="row">
								<div className={dataMode == 'TestForm' ? 'col-md-9 input-text' : 'col-md-10 input-text'}>
									<h5>{question}</h5>
								</div>  
							</div>
							 
						</div>)
						
						case 'text':
						return (
						<div className="full-width default-div">
							<div className="row">
								<div className={dataMode == 'TestForm' ? 'col-md-9 input-text' : 'col-md-10 input-text'}>
									<b>{question}</b>
								</div>  
							</div>
							 
						</div>)
						
						case 'link':
                            return ('')
							
						default:
						return (
							<div>
							   <div className="col-md-12">{question} { comment[15] == 'Y' ? <span className="required">*</span> : ''}</div>
								<div style={{padding: "10px"}} className="answer">
									{ answer }
								</div>        
							</div>)                         
                    }
                })()}
                {comment.sub 
                    && 
                    <PdfData 
                        sheetData={comment.sub} 
                        queId={comment[1].toLowerCase()}  
                        comment= {comment}
                        outputText= {this.props.outputText}
                        kioskResponse= {kioskResponse}
                        needsNote= {needsNote}
                        checkedNeeds= {this.props.checkedNeeds}
                        handleNeeds= {this.props.handleNeeds}
                        allData= {this.props.allData}
                        apiPdfs= {this.props.apiPdfs}
                        dataMode= {this.props.dataMode}
                        loadDataSet= {this.props.loadDataSet}
                        showResponseData= {this.props.showResponseData}
                        delegateUsers= {this.props.delegateUsers}
                        delegateFilter= {this.props.delegateFilter}
						getResponseValue={this.props.getResponseValue}
						saveNeedsNote={this.props.saveNeedsNote}
						removeNeedsNote = {this.props.removeNeedsNote}
                    />
                }
              </div>
            )}
          </div>
        )
    }
}