import React, { Component } from 'react';
import $ from 'jquery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from "@material-ui/core/TextField";

export default class GridDropdownTable extends Component {
	
	constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this);
    }
	
	shoot(event) {
        let name = event.target.name;
		let id = this.props.data.fieldId;
		let value = event.target.value;
		
		let itemArray = this.state.itemArray;
		itemArray[name] = value;
		this.setState({itemArray});
		//console.log('itemArray->',itemArray);
		this.saveResponse();
    }
	
	saveResponse() {
		let options = this.props.data.labels;
		let rows = [];
		let headers = [];
		if(options){    
			let str = options.split('_');
			headers = str[0].split(',');
			rows = str[1].split('|');
		}
		
		let itemArray = this.state.itemArray;
		
		let resArray = [];
		if(rows){
			rows.map(function(rowText,i) {
				i = i+1;
				let heaArray = [];
				headers.map(function(heaText,n) {
					//n = n+1;
					if(n > 0){
					let ids = i+'-'+n;
					let vau = '';
					Object.keys(itemArray).map(function(key) {
						if(key == ids && itemArray[key] !=''){
							vau = itemArray[key];
						}
					});
					heaArray.push(vau);
					}
				})
				
				resArray[i-1] = heaArray;
			}) 
		}
		
		//console.log('resArray->',resArray);
		
		this.props.func(this.props.data.fieldId,resArray); 
		
	}
	
	render(){
		const {data} = this.props;
        let id = data.fieldId;
		let options = data.labels;
		let headers = [];
		let rows = [];
		if(options){    
			let str = options.split('_');
			headers = str[0].split(',');
			rows = str[1].split('|');
		}
		let headerHtml = '';
		if(headers){
			headerHtml = headers.map(function(headerText,i) {
				return (	
				<TableCell>
					{headerText}
				</TableCell>	
				);
			})
		}
		let optionsHtml = '';
		if(rows){
			optionsHtml = rows.map(function(rowText,i) {
				let r = i+1;
				let row = rowText.split(':');
				
				let col1Html = '';
				let col2Html = '';
				
				if(row[1] !== undefined){
					let options = row[1].split(',');
							
					col1Html = options.map(function(opt,i) {
						return (
							<option value={opt} key={i}>{opt}</option>
						);
					})
				}
				
				if(row[2] !== undefined){
					let options = row[2].split(',');
							
					col2Html = options.map(function(opt,i) {
						return (
							<option value={opt} key={i}>{opt}</option>
						);
					})
				}
				
				return (	
					<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
						{row[0]}
						</TableCell>
						{col1Html ?
						<TableCell>
							<select className="form-control" name={r+'-1'}>
								<option value=""> Please Select</option> 
								{col1Html}							
							</select>
						
						</TableCell>
						:null}
						{col2Html ?
						<TableCell>
							<select className="form-control" name={r+'-2'}>
								<option value=""> Please Select</option> 
								{col2Html}							
							</select>
						</TableCell>
						:null}
					</TableRow>
				);
			}) 
		}

		return (
			<TableContainer component={Paper} onChange={this.shoot}>
			  <Table sx={{ minWidth: 566 }} size="small" aria-label="a dense table">
				<TableHead>
				  <TableRow>
					{headerHtml}
				  </TableRow>
				</TableHead>
				<TableBody>
					{optionsHtml}
				</TableBody>
			  </Table>
			</TableContainer>
		);
    }
}

/* export function GridDropdownTable(rowData) {
	
	let id = rowData.fieldId;
	let options = rowData.labels;
	let headers = [];
	let rows = [];
	if(options){    
		let str = options.split('_');
		headers = str[0].split(',');
		rows = str[1].split('|');
	}
	let headerHtml = '';
	if(headers){
		headerHtml = headers.map(function(headerText,i) {
			return (	
			<TableCell>
				{headerText}
			</TableCell>	
			);
		})
	}
	let optionsHtml = '';
	if(rows){
		optionsHtml = rows.map(function(rowText,i) {
			let r = i+1;
			let row = rowText.split(':');
			
			let col1Html = '';
			let col2Html = '';
			
			if(row[1] !== undefined){
				let options = row[1].split(',');
						
				col1Html = options.map(function(opt,i) {
					return (
						<option value={opt} key={i}>{opt}</option>
					);
				})
			}
			
			if(row[2] !== undefined){
				let options = row[2].split(',');
						
				col2Html = options.map(function(opt,i) {
					return (
						<option value={opt} key={i}>{opt}</option>
					);
				})
			}
			
			return (	
				<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCell component="th" scope="row">
					{row[0]}
					</TableCell>
					{col1Html ?
					<TableCell>
						<select className="form-control" name={'R'+r+'-C2'}>
							<option value=""> Please Select</option> 
							{col1Html}							
						</select>
					
					</TableCell>
					:null}
					{col2Html ?
					<TableCell>
						<select className="form-control" name={'R'+r+'-C3'}>
							<option value=""> Please Select</option> 
							{col2Html}							
						</select>
					</TableCell>
					:null}
				</TableRow>
			);
		}) 
	}

	return (
		<TableContainer component={Paper}>
		  <Table sx={{ minWidth: 566 }} size="small" aria-label="a dense table">
			<TableHead>
			  <TableRow>
				{headerHtml}
			  </TableRow>
			</TableHead>
			<TableBody>
				{optionsHtml}
			</TableBody>
		  </Table>
		</TableContainer>
	);
} */
