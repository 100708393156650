import React, { Component } from 'react'; 
import Box from '@mui/material/Box';
import $ from 'jquery';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';

export default class SliderHtml extends Component {

    constructor(props){
        super(props)
        this.shoot = this.shoot.bind(this); 
    }
	
    shoot(event) {
		const x =event.target.value;
		this.props.func(this.props.data.fieldId,x);

    }
	
    render(){
        const {data} = this.props;
        let id = data.fieldId;
		let options = data.labels;
		let kioskResponse = data.kioskResponse;
		let min = data.min;
		let max = data.max;
		let step = data.step;
		let res = [];
		if(options){
			res = options.split(',');
		}
		let ts = '';
		if(kioskResponse.size > 0){
            let resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns;
            }
        }

		return (
			<Box className="mui-slider" width={300} >
				<span>{ res !== undefined ? res[0]:null} &nbsp;&nbsp;</span>
				<Slider key={ts ? parseInt(ts) : parseInt(min)} aria-label="Temperature" defaultValue={ts ? parseInt(ts) : parseInt(min)} valueLabelDisplay="auto" step={parseInt(step)} min={parseInt(min)} max={parseInt(max)} onChange={this.shoot}/>
				<span>&nbsp;&nbsp;{res !== undefined ? res[1]:null}</span>
			</Box>
		);
		
    }
}
