import React, { Component } from 'react';
import 'bootstrap';
import $ from 'jquery';
import axios  from 'axios';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default class Launchpad extends Component {
	
	constructor(props){
        super(props)
        this.state = {
            existGuid:'',
            level:'',
            side:'',
        }
    }

	handleChangeLevel = (event) => {
		this.setState({level:event.target.value});
	};
	
	handleChangeSide = (event) => {
		this.setState({side:event.target.value});
	};
	
	launchpadApiCall=(fieldId,fieldGuid,url,type)=>{
		let level = this.state.level;
		let side = this.state.side;
		let user = 'Sidecar-1';
		
		if(type == 'LP2'){
			user = 'Sidecar-2';
		}else if(type == 'LP3'){
			user = 'Sidecar-3';
		}
		
		let formData = new FormData();
        formData.append('guid', fieldGuid);
        formData.append('name', 'Fred Flintstone');
        formData.append('level', level);
        formData.append('side', side);
        formData.append('user', user);
		let that = this;
		let apiUrl = url+'/save-launching-pad-api';
        axios({
            method: 'POST',
            url: apiUrl,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
			
			let msg = response.data.message;
			if(msg == 'Success'){
				setInterval(function() {
					let existGuid = that.state.existGuid;
					if(existGuid != fieldGuid){
						that.launchpadGetPdf(fieldGuid,url);
					}
				}, 5000);
				let loader = '<div class="loader2" style="display: block;"></div>';
				$('#'+fieldGuid).html(loader);
            }
        })
        .catch(error => {
            console.log('error::', error);
        }) 
	}
	
	launchpadGetPdf=(fieldGuid,url)=>{
		let apiUrl = url+'/get-item?fieldGuid='+fieldGuid;
		axios({
            method: 'GET',
            url: apiUrl,
        })
        .then(response => {
			let pdf = response.data.pdfurl; 
			if(pdf){
				let html = '<div class="view-launch-pdf"><a class="launch-pdf" href='+pdf+' target="_blank"><span>View Report</span> <i class="fas fa-file-pdf" aria-hidden="true"></i></a></div>';
				$('#'+fieldGuid).html(html);
				//this.props.func(this.props.data.fieldId,pdf);
				this.setState({existGuid:fieldGuid});
			}
        })
        .catch(error => {
            console.log('error::', error);
        }) 	
	}
	
	render() {
		
		const {data} = this.props;
        let fieldId = data.fieldId;
        let required = data.required;
        let buttonLabel = data.buttonLabel;
        let fieldGuid = data.fieldGuid;
        let type = data.typeMode;
        let url = data.link;
        let preView = data.preView;
		
		const {level,side} = this.state;
		
		return (<div id={fieldGuid} className="launchpad-section">
			<Box>
			<FormControl sx={{ m: 1, minWidth: 260 }}>
				<InputLabel id="demo-multiple-name-label" size='small'>Amputation Level</InputLabel>
				<Select value={level} label="Amputation Level" labelId="demo-simple-select-helper-label" id={'level-'+fieldId} size='small' onChange={this.handleChangeLevel}>
					<MenuItem  value="Transtibial">Transtibial</MenuItem >
					<MenuItem  value="Transfemoral">Transfemoral</MenuItem >
				</Select>
			</FormControl>
			</Box>
			<Box>
			<FormControl sx={{ m: 1, minWidth: 260 }}>
				<InputLabel id="demo-multiple-name-label" size='small'>Amputation Side</InputLabel>
				<Select value={side} label="Amputation Side" labelId="demo-simple-select-helper-label" id={'side-'+fieldId} size='small' onChange={this.handleChangeSide}>
					<MenuItem  value="Left">Left</MenuItem > 
					<MenuItem  value="Right"> Right</MenuItem > 
					<MenuItem  value="Bilateral"> Bilateral</MenuItem > 
				</Select>
			</FormControl>
			</Box>
			{preView ?
				<Box sx={{ '& button': { m: 1 } }}>
					<Button className="launch-btn" variant="contained" size="medium">{buttonLabel}</Button>
					<p>This button will launch another application</p>
				</Box>
			:
				<Box sx={{ '& button': { m: 1 } }}>	<Button className="launch-btn" variant="contained" size="medium" onClick={() =>this.launchpadApiCall(fieldId,fieldGuid,url,type)}>{buttonLabel}</Button>
				</Box>
			}
			
		</div>
		);
	}
}
