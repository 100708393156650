import React from 'react';
import $ from 'jquery';
import 'bootstrap';
import Popup from "reactjs-popup";
import Overlay from 'react-bootstrap/Overlay';
import ReactHtmlParser from 'react-html-parser';
import { Form,Button,OverlayTrigger,Popover } from 'react-bootstrap';

const noteHtml=(noteData, notes)=>{
	notes = notes.replaceAll('<p></p>\n','');
    if(typeof notes !== 'undefined' && notes !== ''){
        return  <button type="button" style={{backgroundColor: "yellow"}}  className="btn code-dialog btn-sm btn-note" data-toggle="modal" data-target={'#note'+noteData[0]} ><i className="fa fa-sticky-note-o" aria-hidden="true" ></i>
        </button>
    }
}

const noteModal = (noteData, notes)=>{
    if(typeof notes !== 'undefined' && notes !== ''){
        var isImgUrl= /https?:\/\/.*\.(?:png|jpg|gif)/i;
        var imgUrl= notes;
        return <div className="modal video-modal" id={'note'+noteData[0]} style={{zIndex:'1052'}}>
            <div className="modal-dialog ">
                <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Notes <span className='noti-date small ml-2'>{noteData[98] !== undefined ? 'Field Last Updated ' + noteData[98] : null }</span></h4>
                    
                    <button type="button" className="close video-close-btn" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body video-body" id="comment" style={{textAlign:'left'}}>
                    { ReactHtmlParser(imgUrl) }
                </div>
            </div>
            </div>
        </div>
    }
}

const attentHTML= (fieldId, attents,showNeedModal)=> {
    if(typeof attents !== 'undefined' && attents !== ''){        
        var attent = <button type="button" className="btn code-dialog btn-sm btn-note" data-toggle="modal" onClick={()=>showNeedModal(fieldId,attents)} data-target={'#needs'+fieldId}><i className="fa fa-info-circle" style={{color:'red'}}></i></button>
        return attent;        
    }
}


export {noteHtml,noteModal,attentHTML};