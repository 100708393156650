import React, { Component } from 'react';
import $ from 'jquery';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { experimentalStyled as styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

export default class GridCheckTable extends Component {
	
	constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this);
    }
	
	/* shoot(event) {
        let name = event.target.name;
		let id = this.props.data.fieldId;
		let value = event.target.value;
		//console.log('value->',value);
		let itemArray = this.state.itemArray;
		
		if($("input[name='"+name+"']").prop("checked") == true){
			itemArray[name] = value;
		}else{
			itemArray[name] = '';
		}
		
		let options =  this.props.data.options;
		let opt = [];
		if(options){    
			opt = options.split(',');
		}
		
		let numberColumn = this.props.data.numberColumn ? this.props.data.numberColumn : 2;
		let col = 6;
		if(numberColumn == 3){
			col = 4
		}else if(numberColumn == 4){
			col = 3
		}
		let resArray = [];
		if(opt){
			opt.map(function(rowText,i) {
				let optKey = rowText.split('|');
				let vau = false;
				Object.keys(itemArray).map(function(key) {
					if(itemArray[key] == optKey[1]){
						vau = true;
					}
				}); 
				resArray.push(vau);
			})
		}
		
		this.props.func(id,resArray); 
		console.log('resArray->',resArray);
		
    } */
	
	shoot(event) {
        let name = event.target.name;
		let id = this.props.data.fieldId;
		let value = event.target.value;
		
		let itemArray = this.state.itemArray;
		
		if($("input[name='"+name+"']").prop("checked") == true){
			itemArray[name] = true;
		}else{
			itemArray[name] = false;
		}
		
		//itemArray[name] = value;
		this.setState({itemArray});
		//console.log('itemArray->',itemArray);
		this.saveResponse();
    }
	
	saveResponse() {
		
		let options = this.props.data.options;
		
		let labels = this.props.data.labels;
		
		let rows = [];
		let headers = [];
		if(options){    
			headers = options.split(',');
		}
		
		if(labels){    
			rows = labels.split(',');
		}
		
		let itemArray = this.state.itemArray;
		
		let resArray = [];
		if(rows){
			rows.map(function(rowText,i) {
				i = i+1;
				let heaArray = [];
				headers.map(function(heaText,n) {
					n = n+1;
					let ids = i+'-'+n;
					let vau = false;
					Object.keys(itemArray).map(function(key) {
						if(key == ids && itemArray[key] !=''){
							vau = true;
						}
					});
					heaArray.push(vau);
				})
				
				resArray[i-1] = heaArray;
			}) 
		}
		
		console.log('resArray->',resArray);
		this.props.func(this.props.data.fieldId,resArray); 
		
	}

	render(){
		const {data} = this.props;
        let id = data.fieldId;
		let options = data.options;
		const Item = styled(Paper)(({ theme }) => ({
		  ...theme.typography.body2,
		  padding: theme.spacing(0.5),
		  textAlign: 'left',
		  color: theme.palette.text.secondary,
		}));
		
		let labels = data.labels;
		let rows = [];
		if(labels){    
			rows = labels.split(',');
		}
		
		
		//console.log('resAns->',resAns);
		let numberColumn = data.numberColumn ? data.numberColumn : 2;
		let col = 6;
		if(numberColumn == 3){
			col = 4
		}else if(numberColumn == 4){
			col = 3
		}
		let opt = [];
		if(options){    
			opt = options.split(',');
		}
		
		let kioskResponse = data.kioskResponse;
		
        var resAns = [];
        if(kioskResponse.size > 0){
           resAns = kioskResponse.get(id)
        }
		//console.log('resAns->',resAns);
		/* let optionsHtml = '';
		if(opt){
			optionsHtml = opt.map(function(val,i) {
				let optionKey = val.split('|');
				var name = 'action'+i;
				var res1 = resAns ? resAns[i] : false;
				if(res1 == true) {
					return (
						<Grid item xs={1} sm={col} md={col} key={i}>
							<Item>
								<Checkbox value={optionKey[1]} name={name} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} checked={true} /> {optionKey[1]}
							</Item>
						</Grid>
					);
				}else{
					return (
						<Grid item xs={1} sm={col} md={col} key={i}>
							<Item>
								<Checkbox value={optionKey[1]} name={name} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} checked={false} /> {optionKey[1]}
							</Item>
						</Grid>
					);
				}
			})
		} */
		
		
		let optionsHtml = '';
		if(rows){
			optionsHtml = rows.map(function(rowText,i) {
				let r = i+1;
				return (	
					<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
						{rowText}
						</TableCell>
						<TableCell align="center">
							<Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
							{
								opt.map(function(val,j) {
									let optionKey = val.split('|');
									//var name = 'action'+i;
									let c = j+1;
									let res1 = resAns[i] ? resAns[i][j] : false;
									//console.log('res1',resAns[i][j]);
									if(res1 == true) {
										return (
											<Grid item xs={1} sm={col} md={col} key={j}>
												<Item>
													<Checkbox name={r+'-'+c} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} checked={true} /> {optionKey[1]}
												</Item>
											</Grid>
										);
									}else{
										return (
											<Grid item xs={1} sm={col} md={col} key={j}>
												<Item>
													<Checkbox name={r+'-'+c} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} checked={false} /> {optionKey[1]}
												</Item>
											</Grid>
										);
									}
								})
							}
							</Grid>
						</TableCell>
					</TableRow>
				);
			}) 
		}
		
		return (
			<TableContainer component={Paper} onChange={this.shoot}>
			  <Table sx={{ minWidth: 566 }} size="small" aria-label="a dense table">
				<TableBody>
					{optionsHtml}
				</TableBody>
			  </Table>
			</TableContainer>
		);
		
		/* return (
			<Box sx={{ flexGrow: 1 }} onChange={this.shoot}>
				<Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
				{optionsHtml}
				</Grid>
			</Box>
		); */
    }
}
