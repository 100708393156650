import React from 'react';
import $ from 'jquery';
import './App.css';
import MdsBuilder from "./component/MdsBuilder";
//import { load } from "./Helpers/Common";
import { gapi } from 'gapi-script';
import config from "./config";

class App extends React.Component {

   	constructor(props) {
        super(props);
		let subdirectory = $('#subdirectory').val();
		
		//if( localStorage.getItem('username') && localStorage.getItem('username') == 'admin'){
		if( localStorage.getItem('username')){
            this.username = localStorage.getItem('username');
        }else if(this.props.location.pathname == "/" || this.props.location.pathname == "/"+subdirectory || this.props.location.pathname == "/"+subdirectory+"/"){
			
			localStorage.removeItem('username');
			let uid = this.getUrlParameter('uid');
			let substring = '';
			if(uid){
				substring = '?uid='+uid;
			}
			
			if(subdirectory){
				this.props.history.push({
				  pathname: '/'+subdirectory+'/beta'+substring,
				});
			}else{
				this.props.history.push({
				  pathname: '/beta'+substring,
				});
			}
        }    
    }
  	
  	componentDidMount() {
        gapi.load("client", this.initClient);
    }
	
	getUrlParameter(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    render() {
		let subdiry = $('#subdirectory').val();
		if(localStorage.getItem('username') && (this.props.location.pathname == "/" || this.props.location.pathname == '/'+subdiry || this.props.location.pathname == '/'+subdiry+'/')){
   			return (
			    <div className="App"><div className="loader-box"><div className="loader"></div></div><MdsBuilder/></div>
			);
   		}else{
   			return (<div className="App"><div className="loader-box"><div className="loader"></div></div></div>)
   		}
	   	
    }
  
}

export default App;