import React, { Component } from 'react'; 
import $ from 'jquery';
import {DebounceInput} from 'react-debounce-input';

export default class Q6 extends Component {
	constructor(props) {
        super(props);
            this.state={
                q6:'',
            }
        this.shoot = this.shoot.bind(this); 
    }
    
    shoot(event) {
        this.setState({q6:event.target.value})
        this.props.func(this.props.data.fieldId,event.target.value)
    }
    render(){
        const {data} = this.props;
        let id = data.fieldId;
		let kioskResponse = data.kioskResponse;
		return (
            <DebounceInput id={'res_'+id} type="text" debounceTimeout={4000} className={'form-control res_'+id } onChange={this.shoot} />
        );
    }
}

/* <DebounceInput type="text" debounceTimeout={4000} className={'form-control res_'+id } value={kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.q6} onChange={this.shoot}/>
export function FieldQ6(rowData) {
	let id = rowData.fieldId;
	return (
		<TextField focused={false} hiddenLabel id="filled-hidden-label-small" label="" variant="outlined" size="small" />
	);
} */


