import React,{ Component } from 'react';
import $ from 'jquery';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import BasicTextFields from "../../component/MainData/InlineUpload.js";
import Stack from '@mui/material/Stack';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

class CopyFieldDetails extends Component {
	constructor(props) {
        super(props);
	 	this.state ={
			sheetId: '',
		}
		
    }
	
	getValue=(event)=>{
        let name = event.target.name;
		let res = event.target.value;
		this.setState({[name]:res});
	}
	
	loadMdsSheet=(event)=>{
		let sheet = event.target.value;
		let sheetId = '';
		let mdsSheetNames = this.props.copyMdsSheetNames;
		mdsSheetNames.map((val, i) => {
			if(val.name == sheet){
				sheetId = val.sheetId;
			}
		});
		this.setState({sheet,sheetId});
		this.props.getCopyMdsData(sheet);
	}
	
	render(){
		const {getValue,copyMdsSheets,copyMdsSheetNames,textfields,copyAllData,copyAddNewField,showEditHeaders,copyProps,copyUpdateField,setAsGroup} = this.props;
		const {sheet} = this.state;
		let copyMdsHtml = '';
		if(copyMdsSheets){
			
			copyMdsHtml = copyMdsSheets.map(function(val,i) {
				return (
					<option value={val} key={i}>{val}</option>
				);
			})
		}
		
		var textfieldsHtml = textfields.map(function(val,i) {
			let field = val.split(':');
			return (
				<input key={i} className="field-btn" type="button" value={field[1]}  onClick={()=>copyAddNewField(field[0])} /> 
			);
		})
		
		return (
        <div className="build-details">
		
		{/* <div className="btn-checkbox">
				<div className="row">
					<div className="col-md-12">
						<input name="copyMdsRadio" type="radio" value="preMade" checked /> Copy to a pre-made MDS file
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<input name="copyMdsRadio" type="radio" value="NewOne"  /> Create a new MDS file
					</div>
				</div>
		</div> */}
			
			<div className="create-mds-tab">
				<button type="button" className="btn btn-sm btn-primary create-new-mds" data-target="#newMdsPopup" data-toggle="modal"> Create New MDS file </button>
			</div>
			
			<div className="load-mds-button">
				<label>Select "Copy To" MDS file </label>
				<select id="loadCopySelectMds" className="form-control load-select-mds" name="loadMds" onChange={this.loadMdsSheet}>
					<option value="">Please Select</option>
					{copyMdsHtml}
				</select>
			</div>
			
			<div className="accordion" id="field">
				<div className="card">
					<div className="card-header" id="fieldhead1">
						<a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#field1"
						aria-expanded="true" aria-controls="field1">Index & Text Fields</a>
					</div>

					<div id="field1" className="collapse" aria-labelledby="fieldhead1" data-parent="#field">
						<div className="card-body">
							{textfieldsHtml}
						</div>
					</div>
				</div>
			</div>
			{showEditHeaders ? 
			
			<div className="copy-edit-headers">
				
				{copyProps.chooseFieldType == 'Child Header 1' ||  copyProps.chooseFieldType == 'Child Header 2' || copyProps.chooseFieldType == 'Child Header 3' ? (
					<div className="copy-form-group" id="selectTypeDiv">
						<label> Edit Header: </label>
						<select className="form-control" name="chooseFieldType" value={copyProps.chooseFieldType} onChange={getValue}>
							<option value="">Please Select</option>
							<option value="Child Header 1">Child Header 1</option>
							<option value="Child Header 2">Child Header 2</option>
							<option value="Child Header 3">Child Header 3</option>
						</select>
					</div>
				)
				:null}
				
				<div className="copy-form-group" id='qtext'>
					<label> Index & Header Text: </label>
					<textarea className="form-control" type="text" value={copyProps.copyQueInpText} onChange={getValue} name="copyQueInpText"></textarea>
				</div>
				{copyProps.chooseFieldType == 'Child Header 1' ||  copyProps.chooseFieldType == 'Child Header 2' || copyProps.chooseFieldType == 'Child Header 3' ? (
					<div className="copy-form-group">
						<input type="checkbox" value='Y' checked={copyProps.asGroup} className="asGroup" name="asGroup" onChange={setAsGroup}/> Available as a Group
					</div>
				)
				:null}
				
				<div className="copy-form-group mb-2" id='qtext'>
					<label> Note: </label>
					<textarea className="form-control" rows="4" type="text" value={copyProps.copyFiledNote} onChange={getValue} name="copyFiledNote"></textarea>
				</div>
						
				<div className="save-new-mds">
					<button type="button" className="btn btn-sm btn-primary btn-block save-close-mds mb-2" onClick={copyUpdateField}> Save & Close</button>
				</div>
			</div>
			:null}
			
			{copyAllData.length > 0 ? 
			<div className="save-new-mds-guid">
				<button type="button" className="btn btn-sm btn-primary btn-block save-new-mds" onClick={()=>this.props.copyMdsUpdateData('new')}> All New GUIDS & Save & Close </button>
			</div>
			:null}
			
		</div>)
	}
}

export default CopyFieldDetails;