import React, { Component } from 'react';
import $ from 'jquery';
import config from "../config";
import { gapi } from 'gapi-script';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'

export default class TranslationManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			layoutFilesVersion:new Map(),
			layoutMdsFiles:new Map(),
			layoutTranslation:new Map(),
			languagesStatus:new Map(),
			layoutFriendlyName:new Map(),
			selectedFiles:new Map(),
			selectedMethods:new Map(),
			checks:0,
			sheetGuids:[],
			languageData:[],
			selectedLan:'eng',
		}

    }
	
	componentDidMount() {
		
		this.getLauguage();
		this.getLayoutSheets(this.props.selectedInstanceId);
		let that = this;
		
		let intervalId = window.setInterval(function(){
		  that.getLayoutJsonFileVersion();
		}, 120000);
	}
	
	getLayoutSheets=(selectedInstanceId) => {
		
		let ApiUrl = config.mongoApiUrl;
		let url = ApiUrl+'layout-files-ins/'+selectedInstanceId;
		let data = [];
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
            const sheets = response.data;
			let sheetGuids = this.state.sheetGuids;
			let childSheets = sheets.map((sheet) =>{
				let sheetTitle = sheet.LayoutName;
				
				if(sheetTitle != 'Temp-File' && sheetTitle.indexOf('Temp-') == -1){
					return sheet;
				}
				
				
			});
			
			sheets.map((sheet) =>{
				sheetGuids.push(sheet.LayoutGuid);
			});
			
			childSheets = childSheets.filter(function(x) {
				 return x !== undefined;
			});
			this.setState({childSheets,sheetGuids});
			let that = this;
			setTimeout(function(){
				that.getLayoutJsonFileVersion();
				//that.checkStatasLayout();
				//that.storeInstanceMongo('hin');
			}, 5000);
        })
		
	}
	
	getLauguage=()=>{
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'f2-get-language';
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			let lan = response.data;
			if(lan.length > 0){
				this.setState({languageData:lan});
			}
		}).catch(error => {
			console.log('error::', error);
		}) 
	}
	
	setUploadToMongo=(event)=>{
		let sheet = event.target.name;
		let type = event.target.id;
		let selectedFiles = this.state.selectedFiles;
		
		if($("#"+type).prop("checked") == true){
			selectedFiles.set(type,sheet);
			this.setState({[type]:true});
		}else{
			selectedFiles.delete(type);
			this.setState({[type]:false});
		}
		
		this.setState({selectedFiles});
	}
	
	getLayoutJsonFileVersion=()=>{
		let sheetGuids = this.state.sheetGuids;
		
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'layout-json-version-v2';
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(sheetGuids),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			
			let files = response.data;
			files.map((file,i)=>{
				if(file.Version){
					let layoutFilesVersion = this.state.layoutFilesVersion;
					layoutFilesVersion.set(file.DefaultLayoutGuid,file.Version);
					
					let layoutMdsFiles = this.state.layoutMdsFiles;
					layoutMdsFiles.set(file.DefaultLayoutGuid,file.mdsFiles);
					
					let layoutTranslation = this.state.layoutTranslation;
					if(file.translation){
						layoutTranslation.set(file.DefaultLayoutGuid,file.translation);
					}
					
					let languagesStatus = this.state.languagesStatus;
					if(file.languages){
						languagesStatus.set(file.DefaultLayoutGuid,file.languages);
					}
					
					let layoutFriendlyName = this.state.layoutFriendlyName;
					layoutFriendlyName.set(file.DefaultLayoutGuid,file.FriendlyName);
					//console.log('layoutFilesVersion->',layoutFilesVersion);
					//console.log('layoutMdsFiles->',layoutMdsFiles);
					//console.log('layoutTranslation->',layoutTranslation);
					//console.log('languagesStatus->',languagesStatus);
					this.setState({layoutFilesVersion,layoutMdsFiles,layoutTranslation,languagesStatus,layoutFriendlyName});
				}
			});
			
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	setAutoRequest=(event)=>{
		let layoutGuid = event.target.name;
		if($("#"+layoutGuid).prop("checked") == false){
			this.setState({[layoutGuid]:false});
		}else{
			this.setState({[layoutGuid]:true});
		}
	}
	
	selectLanguage=(event)=>{
		this.state.childSheets.map((sheet,i)=>{
			let sheetGuid = sheet.LayoutGuid;
			this.setState({['lan'+sheetGuid]:''});
			this.setState({['excel-'+sheetGuid]:''});
		});
		let layoutGuid = event.target.name;
		let val = event.target.value;
		this.setState({selectedLanguage:val,selectedLayout:layoutGuid,['lan'+layoutGuid]:val});
	}
	
	selectMethod=(event)=>{
		this.state.childSheets.map((sheet,i)=>{
			let sheetGuid = sheet.LayoutGuid;
			this.setState({['method'+sheetGuid]:''});
		});
		let layoutGuid = event.target.name;
		let val = event.target.value;
		this.setState({selectedMethods:new Map(),selectedMethod:val,selectedLayout:layoutGuid,['method'+layoutGuid]:val});
	}
	
	selectMethodMt=(event)=>{
		let selectedMethods = this.state.selectedMethods;
		let item = event.target.name;
		selectedMethods.set(item, Array.from(event.target.selectedOptions, (item) => item.value))
        this.setState({selectedMethods:selectedMethods,selectedMethod:''});
    }
	
	getTranslatedData=(sheetName,sheetGuid,sheetId)=>{
		let ApiUrl = config.ApiUrlTranslation;
		let ApiKey = config.ApiKeyTranslation;
		let selectedLanguage = this.state.selectedLanguage;
		let selectedMethod = this.state.selectedMethod;
		let autoRequest = this.state['auto-'+sheetId];
		this.setState({['excel-'+sheetGuid]:'true'});
		
		let methods = this.state.selectedMethods.get(sheetGuid);
		if(!selectedLanguage){
			return false;
		}
		//console.log('methods->',methods);
		let data = {
			"data":[sheetGuid],
			"languages":[selectedLanguage],
			"autoRequest":autoRequest,
			"translateService":selectedMethod ? [selectedMethod] : methods
		}
		
		axios({
			method: 'POST',
			url: ApiUrl,
			data: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				'X-API-Key': ApiKey,
			}
		})
		.then(response => {
			
			let rsData = response.data;
			if(rsData.excelLink){
				
				let that = this;
				setTimeout(function(){
					that.setState({['excel-'+sheetGuid]:rsData.excelLink});
					//that.getLayoutJsonFileVersion();
					that.checkStatasLayout(sheetGuid);
				}, 50000);
				
			}
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	checkStatasLayout=(sheetGuid)=>{
		let checks = this.state.checks;
		let lang = this.state['lan'+sheetGuid];
		this.storeInstanceMongo(lang);
		let ApiUrl = config.mongoApiUrl;
		let url = ApiUrl+'translation-status/'+sheetGuid+'/'+lang;
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			let statuss = response.data;
			//console.log('checks->',checks);
			let that = this;
			if(statuss == 'success'){
				checks = 0;
				let url = ApiUrl+'translated-data/'+sheetGuid+'/'+lang;
				axios({
					method: 'GET',
					url: url,
					headers: {
						'Content-Type': 'application/json',
					}
				})
				.then(response => {
					let trandata = response.data;
					if(trandata.length > 0){
						let MdsFiles = this.state.layoutMdsFiles.get(sheetGuid);
						that.createMdsAndLayout(sheetGuid,lang,MdsFiles,trandata);
					}
				});
				
			}else{
				if(checks <= 3){
					window.setInterval(function(){
					  that.checkStatasLayout(sheetGuid);
					  checks = checks+1;
					  this.setState({checks});
					}, 50000);
					 
					/* setInterval(
						that.checkStatasLayout(sheetGuid);
					    checks = checks+1;
					    this.setState({checks});
					, 50000); */
				}
			}
			
			
			
		});
		
	}
	
	storeInstanceMongo=(lang)=>{
		let instanceName = this.props.selectedInstanceName+'_'+lang.toUpperCase();
		
		let ApiUrl = config.mongoApiUrl;
		let url = ApiUrl+'get-instance/'+instanceName;
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			console.log(response.data);
			
			if(response.data){
				
				this.setState({instanceName:instanceName,instanceId:response.data});
				
			}else{
				let uid = ulid();
				let url1 = ApiUrl+'store-instance';
				let data ={
					"InstanceName": instanceName,
					"InstanceGuild": uid
				}
				axios({
					method: 'POST',
					url: url1,
					data: JSON.stringify(data),
					headers: {
						'Content-Type': 'application/json',
					}
				})
				.then(response => {
					//alert(response.data);
					this.setState({instanceName,instanceId:uid});
				}).catch(error => {
					console.log('error::', error);
				})
			}
		});
	}
	
	createMdsAndLayout=(layoutGuid,lang,MdsFiles,trandata)=>{
		
		if(MdsFiles.length > 0){
			
			MdsFiles.map((val, i) => {
				
				let ApiUrl = config.mongoApiUrl;
				
				let url = ApiUrl+'mds-file-guid/'+val.MDSGuid+'_'+lang.toUpperCase();
				axios({
					method: 'GET',
					url: url,
					headers: {
						'Content-Type': 'application/json',
					}
				})
				.then(response => {
					let data = response.data;
					if(data.Fields){
						let dataFields = data.Fields
						let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
						
						dataFields.map((field,i)=>{
							
							trandata.map((val,i)=>{
								if(field[117] == val['FieldGUID']){
									field[4] = val['VisibleText'] ? val['VisibleText'] : field[4];
									field[9] = val['OutputText'] ? val['OutputText'] : field[9];
									field[6] = val['Options'] ? val['Options'] : field[6];
									field[40] = val['ButtonLabel'] ? val['ButtonLabel'] : field[40];
									field[41] = val['LabelValues'] ? val['LabelValues'] : field[41];
									field[13] = val['Tags'] ? val['Tags'] : field[13];
									field[12] = val['Notes'] ? val['Notes'] : field[12];
									
									
								}
								
								//field[91] = field[91]+'_'+lang.toUpperCase();
							});
						});
						
						//data['MDSGuid'] = data['MDSGuid']+'_'+lang.toUpperCase();
						//data['MDSName'] = data['MDSName']+'_'+lang.toUpperCase();
						data['InstanceName'] = this.state.instanceName;
						data['InstanceGuild'] = this.state.instanceId;
						data['CreatedDate'] = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
						data['ModifiedDate'] = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
						data['Version'] = '1.01';
						data['Mode'] = 'Build';
						
						this.mongoUpdateMds(data);
					}else{
						let url = ApiUrl+'mds-file-guid/'+val.MDSGuid;
						axios({
							method: 'GET',
							url: url,
							headers: {
								'Content-Type': 'application/json',
							}
						})
						.then(response => {
							let data = response.data;
							if(data.Fields){
								let dataFields = data.Fields
								let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
								
								dataFields.map((field,i)=>{
									
									trandata.map((val,i)=>{
										if(field[117] == val['FieldGUID']){
											field[4] = val['VisibleText'] ? val['VisibleText'] : field[4];
											field[9] = val['OutputText'] ? val['OutputText'] : field[9];
											field[6] = val['Options'] ? val['Options'] : field[6];
											field[40] = val['ButtonLabel'] ? val['ButtonLabel'] : field[40];
											field[41] = val['LabelValues'] ? val['LabelValues'] : field[41];
											field[13] = val['Tags'] ? val['Tags'] : field[13];
											field[12] = val['Notes'] ? val['Notes'] : field[12];
											
											
										}
										
										field[91] = field[91]+'_'+lang.toUpperCase();
									});
								});
								
								data['MDSGuid'] = data['MDSGuid']+'_'+lang.toUpperCase();
								data['MDSName'] = data['MDSName']+'_'+lang.toUpperCase();
								data['InstanceName'] = this.state.instanceName;
								data['InstanceGuild'] = this.state.instanceId;
								data['CreatedDate'] = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
								data['ModifiedDate'] = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
								data['Version'] = '1.01';
								data['Mode'] = 'Build';
								
								this.mongoStoreNewMds(data);
							}
							
						})
					}
				})
				
			});
		}
		
		if(layoutGuid){
			
			let ApiUrl = config.mongoApiUrl;
			let url = ApiUrl+'layout-file-guid/'+layoutGuid;
			axios({
				method: 'GET',
				url: url,
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(response => {
				let data = response.data;
				let layoutFields = data.Fields
				let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
				
				layoutFields.map((field,i)=>{
					
					trandata.map((val,i)=>{
						if(field[117] == val['FieldGUID']){
							field[4] = val['VisibleText'] ? val['VisibleText'] : field[4];
							field[9] = val['OutputText'] ? val['OutputText'] : field[9];
							field[6] = val['Options'] ? val['Options'] : field[6];
							field[40] = val['ButtonLabel'] ? val['ButtonLabel'] : field[40];
							field[41] = val['LabelValues'] ? val['LabelValues'] : field[41];
							field[13] = val['Tags'] ? val['Tags'] : field[13];
							field[12] = val['Notes'] ? val['Notes'] : field[12];
							
							
						}
						
						field[91] = field[91]+'_'+lang.toUpperCase();
						field[129] = field[129]+'_'+lang.toUpperCase();
					});
				});
				
				data['LayoutGuid'] = data['LayoutGuid']+'_'+lang.toUpperCase();
				data['LayoutName'] = data['LayoutName']+'_'+lang.toUpperCase();
				data['InstanceName'] = this.state.instanceName;
				data['InstanceGuild'] = this.state.instanceId;
				data['CreatedDate'] = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
				data['ModifiedDate'] = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
				data['Version'] = '1.01';
				data['Mode'] = 'Build';
				this.mongoStoreNewLayout(data);
				
			})
			
		}
		
	}
	
	mongoStoreNewMds=(data)=>{
		
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'store-mds-files';
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			//alert('MDS File created successfully!');
			/* let res = response.data;
			
			if(res == 'Inserted Successfully!'){
				$('#createBuild').modal('hide');
				//this.uploadMdsJsonMongoDb(mdsDataName);
				//alert('MDS File created successfully!');
			} */
		}).catch(error => {
			console.log('error::', error);
		})
		
	}
	
	mongoUpdateMds=(data)=>{
		
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'update-mds-lang';
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			//alert('MDS File created successfully!');
			/* let res = response.data;
			
			if(res == 'Inserted Successfully!'){
				$('#createBuild').modal('hide');
				//this.uploadMdsJsonMongoDb(mdsDataName);
				//alert('MDS File created successfully!');
			} */
		}).catch(error => {
			console.log('error::', error);
		})
		
	}
	
	mongoStoreNewLayout=(data)=>{
		
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'store-layout-files';
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			/* let res = response.data;
			
			if(res == 'Inserted Successfully!'){
				$('#createBuild').modal('hide');
				this.getLayoutSheets(this.state.selectedInstanceId);
				this.getLayoutData();
				this.uploadLayoutJsonMongoDb(mdsDataName);
				alert('Layout File created successfully!');
			} */
		}).catch(error => {
			console.log('error::', error);
		})
		
	}
	
	getLayoutAllLauguage=()=>{
		
		let layoutSheetGuids = [];
		this.props.childSheetNames.map((sheet,i)=>{
			this.setState({['auto-'+sheet.sheetId]:true});
			let sheetGuids = this.props.sheetGuids;
			let sheetGuid = sheetGuids.get(sheet.name);
			layoutSheetGuids.push(sheetGuid);	
		});
		
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'layout-all-language';
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(layoutSheetGuids),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			let translatedData = response.data;
			
			//console.log('translatedData->',translatedData);
			//return translatedData;
			/* if(translatedData.length > 0){
				this.createLayout(sheetName,sheetId,selectedLanguage,translatedData);
			}
			if(files.length > 0){
				this.setState({layoutLanguageData:files,selectedLanguage:layoutGuid+'_'+layoutLanguage});
			}else{
				this.setState({layoutLanguageData:[],selectedLanguage:''});
			} */
		}).catch(error => {
			console.log('error::', error);
		}) 
	}
	
	getLayoutLauguageJson=(sheetName,layoutGuid,selectedLanguage,sheetId)=>{
		
		//this.setState({layoutLanguageData:[],selectedLanguage:layoutGuid+'_'+layoutLanguage});
		let layout = {
			"layoutGuid": layoutGuid,
			"layoutLanguage": selectedLanguage,
		}
		
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'layout-language-json';
		axios({
			method: 'POST',
			url: url,
			data: layout,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			let translatedData = response.data;
			
			//console.log('translatedData->',translatedData);
			
			/* if(translatedData.length > 0){
				this.createLayout(sheetName,sheetId,selectedLanguage,translatedData);
			}
			if(files.length > 0){
				this.setState({layoutLanguageData:files,selectedLanguage:layoutGuid+'_'+layoutLanguage});
			}else{
				this.setState({layoutLanguageData:[],selectedLanguage:''});
			} */
		}).catch(error => {
			console.log('error::', error);
		}) 
	}
	
	createLayout=(sheetName,sheetId,selectedLanguage,translatedData)=>{
		let spreadsheetId = this.props.childSpreadsheetId;
		let layoutName = sheetName+'_'+selectedLanguage.toUpperCase();
		
		let params = {
            spreadsheetId: spreadsheetId,
            sheetId: sheetId,
        };

		let copySheetToAnotherSpreadsheetRequestBody = {
            destinationSpreadsheetId:spreadsheetId,
        };
		
        let that=this;
        let request = gapi.client.sheets.spreadsheets.sheets.copyTo(params, copySheetToAnotherSpreadsheetRequestBody);
		request.then(
			response => {
				let params1 =  {
					spreadsheetId:spreadsheetId,
				};
				let getRequest = gapi.client.sheets.spreadsheets.get(params1);
				getRequest.then(function(response) {
					let length = response.result.sheets.length;
					let lastSheetId = response.result.sheets[length-1].properties.sheetId;
					
					let requests ={
						"requests": [
							{
							  "updateSheetProperties": {
								"properties": {
								  "sheetId": lastSheetId,
								  "title": layoutName,
								},
								"fields": "title"
							  }
							}
						]
					}
					let renameRequest = gapi.client.sheets.spreadsheets.batchUpdate(params1,requests);
					renameRequest.then(function(response) {
						that.updateLayout(spreadsheetId,layoutName,translatedData);
					}, function(reason) {
						alert(reason.result.error.message);
					});
				}, function(reason) {
					alert(reason.result.error.message);
				});
			}, function(reason) {
				alert(reason.result.error.message);
		});
	}
	
	updateLayout=(spreadsheetId,layoutName,translatedData) =>{
		gapi.client.sheets.spreadsheets.values
		.get({
			spreadsheetId: spreadsheetId,
			range:layoutName, 
		})
		.then(
			response => {
				const allData = response.result.values;
				let currentDate = new Date().toLocaleString("en-US").replace(',','');
				//console.log('allData->',allData);
				if(allData.length > 0){
					let rowId='';
					let updateRows=[];
					allData.map((field,i)=>{
						rowId = (i+1);
						if(translatedData){
							translatedData.map((lan,i)=>{
								if(lan['FieldGUID'] == field[117]){
									/* language = lan['Language'].toUpperCase();
									fieldData['Visible Text'] = lan['VisibleText'] ? lan['VisibleText'] : field[4];
									fieldData['Output Text'] = lan['OutputText'] ? lan['OutputText'] : field[9];
									fieldData['Options'] = lan['FieldTypeOptions'] !== undefined ? lan['FieldTypeOptions']['Options'] : options;
									fieldData['GridLabelValue'] = lan['GridLabelValue'] !== undefined ? lan['GridLabelValue'] : gridLabel;
									fieldData['Notes'] = lan['Notes'] !== undefined ? lan['Notes'] : notes;
									fieldData['Tags'] = lan['Tags'] ? lan['Tags'] : field[13];
									fieldData['Button Label'] = lan['ButtonLabel'] ? lan['ButtonLabel'] : field[40]; */
									let VisibleText = lan['VisibleText'] ? lan['VisibleText'] : field[4];
									let OutputText = lan['OutputText'] ? lan['OutputText'] : field[9];
									let ButtonLabel = lan['ButtonLabel'] ? lan['ButtonLabel'] : field[40];
									let Tags = lan['Tags'] ? lan['Tags'] : field[13];
									let Options = lan['FieldTypeOptions'] !== undefined ? lan['FieldTypeOptions']['Options'].join(',') : field[6];
									//let GridLabelValue = lan['GridLabelValue'] !== undefined ? lan['GridLabelValue'].join(',') : field[6];
									
									let row1 = {
										"range": layoutName+'!E'+rowId,
										"majorDimension": "ROWS",
										"values": 
										[
											[VisibleText]
										]
									};
									
									let row2 = {
										"range": layoutName+'!J'+rowId,
										"majorDimension": "ROWS",
										"values": 
										[
											[OutputText]
										]
									};
									
									let row3 = {
										"range": layoutName+'!AO'+rowId,
										"majorDimension": "ROWS",
										"values": 
										[
											[ButtonLabel]
										]
									};
									
									let row4 = {
										"range": layoutName+'!T'+rowId,
										"majorDimension": "ROWS",
										"values": 
										[
											[Tags]
										]
									};
									
									let row5 = {
										"range": layoutName+'!G'+rowId,
										"majorDimension": "ROWS",
										"values": 
										[
											[Options]
										]
									};
									
									updateRows.push(row1);
									updateRows.push(row2);
									updateRows.push(row3);
									updateRows.push(row4);
									updateRows.push(row5);
								}
							});

						}
					})
					
					//console.log('updateRows->',updateRows);
					
					var params = {
						spreadsheetId: spreadsheetId,
					}
					let batchUpdateValuesRequestBody = {
						valueInputOption: 'USER_ENTERED',
						data: updateRows, 
					};
					let that = this;
					let updateRequest = gapi.client.sheets.spreadsheets.values.batchUpdate(params,batchUpdateValuesRequestBody);
					updateRequest.then(function(response) {
						alert('New Layout created Successfully!');
					}, function(reason) {
						console.error('error: ' + reason.result.error.message);
					});
				}
				
				
			},function(reason) {
				alert(reason.result.error.message);
			}
		);

	}
	
	render() {
		
		const {childSheets, layoutFilesVersion,layoutFriendlyName,layoutMdsFiles,layoutTranslation,languagesStatus,selectedFiles,languageData} = this.state;
		
		//console.log('childSheets->',childSheets);
		
		let MdsFiles =[];
		
		let optionsHtml = languageData.map(function(val,i) {
			return (
				<option value={val['code3']} key={i}>{val['full']}</option>
			);
			
		})
		
		let totalFieldCountM = 0;
		let totalFieldCountC = 0;
		
		return (
			<div className="mds-manager-mongo container">
				
				<div className="mongo-header">
					<div className="tra-page-title"><h4>Translation Manager</h4></div>
				</div>
				
				<div className="tra-child-sheets">
					<div className="tra-top-action-icon">
						<div className="tra-top-action-span">
							<div className="tra-mongo-name">Layout Name</div>
							<div className="tra-mongo-update">Last Updated</div>
						</div>
						<div className="tra-top-action-span2">
							<div className="tra-mongo-lable tb1">Select Langauge</div>
							<div className="tra-mongo-lable tb2">Method</div>
							<div className="tra-mongo-lable tb3">Populate Mongo</div>
							<div className="tra-mongo-lable tb4">Create Layout</div>
							<div className="tra-mongo-lable tb5">Translation Status</div>
							<div className="tra-mongo-lable tb6">DB</div>
							<div className="tra-mongo-lable tb7">Ver.#</div>
							<div className="tra-mongo-lable tb8">Layout Translated</div>
						</div>
					</div>
					<div className="mds-sheet-div">
						{childSheets ?
						<div className="mds-file-list">
						<ul className="list-group manage-form">
							{childSheets.map((sheet, i) => {
								
								
								/* let apiName = sheetApiName.get(sheet.name);
								
								let totalRows = '';
								let lastUpdate = '';
								if(apiName){
									apiName = apiName.split('_');
									lastUpdate = apiName[2];
									totalRows = apiName[3];
									
									if(Number(totalRows)){
										totalFieldCountC = parseInt(totalFieldCountC) + parseInt(totalRows);
									}
								} */
								
								let totalRows = '';
								let lastUpdate = '';
								
								/* let version = sheetVersion.get(sheet.name);
								let sheetGuid = sheetGuids.get(sheet.name);
								let mongoVersion = layoutFilesVersion.get(sheetGuid);
								let friendlyName = layoutFriendlyName.get(sheetGuid); */
								let version = sheet.Version;
								let sheetGuid = sheet.LayoutGuid;
								let mongoVersion = 'tttt';
								let friendlyName = sheet.FriendlyName;
								
								/* let lanData = this.getLayoutAllLauguage(sheetGuid);
								console.log('lanData->',lanData); */
								
								/* let classn = 'not-in-mongo';
								if(mongoVersion && (mongoVersion == version || version == '')){
									classn = 'has-in-mongo';
								} */
								let classn = 'has-in-mongo';
								let mdsClass = 'not-in-mongo';
								
								let mdsFilestr = '';
								
								if(layoutMdsFiles.get(sheetGuid) != undefined){
									let MdsFiles = layoutMdsFiles.get(sheetGuid);
									/* mdsClass = 'has-in-mongo';
									MdsFiles.map((msheet, i) => {
										if(msheet.has == false){
											mdsClass = 'not-in-mongo';
										}
									}); */
									
									mdsFilestr = MdsFiles.map((msheet, i) => {
										return (
											<tr><td>&nbsp;</td><td>&nbsp;</td><td>{msheet.MDSName}</td><td>{msheet.MDSGuid}</td><td>{msheet.Version}</td></tr>
										);
									})
								}
								let statusclass = 'status-hide';
								let layoutStatusData = '';
								if(this.state['excel-'+sheetGuid] && this.state['lan'+sheetGuid] && this.state['lan'+sheetGuid] != 'eng'){
									statusclass = 'status-pending';
								}
								if(languagesStatus.get(sheetGuid) != undefined){
									let lanStatus = languagesStatus.get(sheetGuid);
									layoutStatusData = lanStatus.map((val, i) => {
										if(this.state['lan'+sheetGuid] == val.lang){
											statusclass = 'status-'+val.status;
										}
										
										return (
											<tr><td>{val.lang}</td><td>{val.status}</td><td>{val.info}</td></tr>
										);
										
									})
								}
								
								let translatedHtml = '';
								if(layoutTranslation.get(sheetGuid) != undefined){
									let translatedFiles = layoutTranslation.get(sheetGuid);
									mdsClass = 'has-in-mongo';
									
									translatedHtml = translatedFiles.map((lan, i) => {
										let mdsFilestrlan
										if(layoutMdsFiles.get(sheetGuid) != undefined){
											let MdsFileslan = layoutMdsFiles.get(sheetGuid);
											mdsFilestrlan = MdsFileslan.map((msheet, i) => {
												return (
													<tr><td>&nbsp;</td><td>&nbsp;</td><td>{msheet.MDSName+'_'+lan.LanguageCode.toUpperCase()}</td><td>{msheet.MDSGuid+'_'+lan.LanguageCode.toUpperCase()}</td><td>{msheet.Version}</td></tr>
												);
											})
										}
										return (
											<table className="layout-tbl-lan table-bordered">
												<tr>&nbsp;</tr>
												<tr><td width="100">{lan.Language}</td><td width="200">{lan.LayoutName}</td><td width="200">{lan.FriendlyName}</td><td width="200">{lan.LayoutGuid+'_'+lan.LanguageCode.toUpperCase()}</td><td width="50">{lan.Version}</td></tr>
												<tr>&nbsp;</tr>
												<tr>
													<th width="100">&nbsp;</th>
													<th width="200">&nbsp;</th>
													<th width="200">Required MDS Names</th>
													<th width="200">MDS GUID Of Translated Fields</th>
													<th>&nbsp;</th>
												</tr>
												{mdsFilestrlan}
											</table>
										);
									}) 
								}
								
								if(classn == 'has-in-mongo'){	
								return(<li id={'layout-'+sheet.LayoutGuid} key={i} className={"tra-list-group-item show"}>
								<div className="tra-sheet-name-i ">{sheet.LayoutName} </div>
								<div className="tra-sheet-last-update">{sheet.ModifiedDate} </div>
								<div className="tra-mds-download-icon">
									<div className="sheet-language tb1">
									<select className="select-lan" name={sheetGuid} value={this.state['lan'+sheetGuid]} onChange={this.selectLanguage}>
										{optionsHtml}
									</select>
									</div>
									
									<div className="sheet-method tb2">
									{/* this.state['auto-'+sheet.sheetId] == true ?
										<select className="select-lan select-method" name={sheetGuid} value={this.state['method'+sheetGuid]} onChange={this.selectMethod}>
											<option value="" selected="true" disabled="disabled">--Select--</option>
											<option value="chatGPT">chatGPT</option>
											<option value="googleTranslate">googleTranslate</option>
											<option value="deepL">deepL</option>
										</select>
									:
										<select className="select-lan select-method" name={sheetGuid} value={this.state.selectedMethods.get(sheetGuid)} onChange={this.selectMethodMt} multiple>
											<option value="" selected="true" disabled="disabled">--Select--</option>
											<option value="chatGPT">chatGPT</option>
											<option value="googleTranslate">googleTranslate</option>
											<option value="deepL">deepL</option>
										</select>
									 */}
										<select className="select-lan select-method" name={sheetGuid} value={this.state['method'+sheetGuid]} onChange={this.selectMethod}>
											<option value="" selected="true" disabled="disabled">--Select--</option>
											<option value="chatGPT">chatGPT</option>
											<option value="googleTranslate">googleTranslate</option>
											<option value="deepL">deepL</option>
										</select>
									</div>
									
									
									<div className="tra-mongo-val tb3"><input id={'auto-'+sheet.LayoutGuid} className="mds-action-btn" type="checkbox" name={'auto-'+sheet.LayoutGuid} checked={this.state['auto-'+sheet.LayoutGuid] == true ? 'checked' : ''} onChange={this.setAutoRequest}/></div>
									<div className="tra-mongo-val tb4" onClick={()=>this.getTranslatedData(sheet.name,sheetGuid,sheet.LayoutGuid)}>
									<i className="fa fa-language"></i>
									{this.state['excel-'+sheetGuid] ?
										<a href={this.state['excel-'+sheetGuid]} target="_blank"><i className="fa fa-download excel-download"></i></a>
									:null}
									</div>
									<div className="tra-mongo-val tb5" data-target={"#layoutStatus"+sheetGuid} data-toggle="modal"><i id={'status-'+sheetGuid} className={"fa fa-circle "+statusclass.toLowerCase()} aria-hidden="true"></i></div>
									
									<div className="tra-mongo-val tb6"><i id={'guid-'+sheetGuid} className={"fa fa-circle "+classn} aria-hidden="true"></i></div>
									
									<div className="tra-mongo-val tb7"><i>{version && version != 1 ? version : '1.01' }</i></div>
									{ this.state['excel-'+sheetGuid] || mdsClass == 'has-in-mongo' ? 
										<div className="tra-mongo-val pointer tb8" data-target={"#layoutDataModal"+sheetGuid} data-toggle="modal"><i id={'guid-'+sheetGuid} className={"fa fa-circle has-in-mongo"} aria-hidden="true"></i></div>
									:
										<div className="tra-mongo-val tb8"data-target={"#layoutDataModal"+sheetGuid} data-toggle="modal"><i id={'guid-'+sheetGuid} className={"fa fa-circle not-in-mongo"} aria-hidden="true"></i></div>
									}
									
									<div className="modal" id={"layoutStatus"+sheetGuid} role="dialog">
										<div className="language-popup modal-dialog modal-lg">
											<div className="modal-content">
											  <div className="modal-header">
												<h5> Layout Name: {sheet.LayoutName} </h5>
												<button type="button" className="close" data-dismiss="modal">&times;</button>
											  </div>
											  <div className="modal-body">
													{layoutStatusData ?
													<table className="layout-tbl-lan table-bordered">
													  <tr>
														<th width="100">Language</th>
														<th width="200">Status</th>
														<th width="200">Info</th>
													  </tr>
													  {layoutStatusData}
													</table>
													: <div className="tran-empty">Translation status not found!</div>
													}
											  </div>
											</div>
										</div>
									</div>
									
									<div className="modal" id={"layoutDataModal"+sheetGuid} role="dialog">
										<div className="language-popup modal-dialog modal-lg">
											<div className="modal-content">
											  <div className="modal-header">
												<h5> Layout Name: {sheet.LayoutName} </h5>
												<button type="button" className="close" data-dismiss="modal">&times;</button>
											  </div>
											  <div className="modal-body">
													<table className="layout-tbl-lan table-bordered">
													  <tr>
														<th width="100">Language</th>
														<th width="200">Layout Name</th>
														<th width="200">Layout Friendly Name</th>
														<th width="200">Layout GUID</th>
														<th width="50">Version</th>
													  </tr>
													  <tr>
														<td>English</td>
														<td>{sheet.LayoutName}</td>
														<td>{friendlyName ? friendlyName : sheet.LayoutName}</td>
														<td>{sheetGuid}</td>
														<td>{version}</td>
													  </tr>
													  <tr>&nbsp;</tr>
													  <tr>
														<th width="100">&nbsp;</th>
														<th width="200">&nbsp;</th>
														<th width="200">Required MDS Names</th>
														<th width="200">MDS GUID Of Translated Fields</th>
														<th>&nbsp;</th>
													  </tr>
													  {mdsFilestr}
													</table>
													{translatedHtml}
											  </div>
											</div>
										</div>
									</div>
									
								</div>
								</li>)
								}
							})}
							</ul>
						</div>
						:null}
					</div>
				</div>
			</div>
		);	
	}	
}
