import React, { Fragment, useState } from 'react';
import $ from 'jquery';
import { makeStyles }   from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import STLViewer from 'stl-viewer'
import config from "../../config";
import axios  from 'axios';
import {OBJModel} from 'react-3d-viewer';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: '10px 0px'
    },
    fileGrid: {
        border: '1px solid #c5c5c5',
        margin: '10px -10px',
    },
    inputFile: {
        marginTop: '20px',
    },
    uploadButton: {
        borderRadius: '20px',
    },
    spinner: {
        marginLeft: '15px'
    }
}));

export default function FieldObjFile(props) {
	const fieldId = props.data.fieldId;
	const classes = useStyles();
    const [title, setTitle]             = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [fileName, setFileName]       = useState('');
    const [file, setFile]     = useState('');
    const [path, setPath]     = useState('');
    const [embedCode, setEmbedCode]         = useState('');
    const [showSpinner, setShowSpinner]     = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(true);
    const [showUploadButton, setShowUploadButton] = useState(false);
	const [showEmbedCode, setShowEmbedCode] = useState(false);
	const [dataField, setDataField] = useState(props.dataField);
	
	/* if(!dataField && props.dataField){
		setDataField(props.dataField);
		let str = props.dataField.split(',');
		//let url = str[0];
		//let ftype = str[0].split('.').pop();
		setPath(str[0]);
		setTitle(str[1]);
		setDescription(str[2]);
		setDate(str[3]);
	} */
	
	const handleChange = (files) => {
        setFileName(files[0].name);
        setFile(files[0]);
		setPath('');
    }

	const showUpload = () => {
		setUploadSuccess(false);
		setShowUploadButton(true);
	}
	
	const deleteUpload = () => {
		$( "#ans"+fieldId ).html('');
		setDataField('');
		setPath('');
	}
	
	const uploadSubmit = () => {
		let ApiUrl = config.mongoApiUrl;
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		//let blobURL = URL.createObjectURL(file);
		let fileType = fileName.split('.').pop();
		
		if(file){
			
			let formData = new FormData();
			formData.append('file', file);
			formData.append('name', title);
			formData.append('description', description);
			
			//let url = ApiUrl+'uploadfile/file_upload1.php';
			let url = ApiUrl+'obj-file-upload';
	
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				let data = response.data;
				//console.log('data->',data);
				let urlPath = data[2];
				
				setPath(urlPath);
				setDate(date);
				setUploadSuccess(true);
				setShowEmbedCode(true);
				setShowUploadButton(false);
				setShowSpinner(false);
				setFileName('');
				setDataField(urlPath);

				let res = urlPath+','+title+','+description+','+date;
				props.func(fieldId,res);
			})
			.catch(error => {
				console.log('error::', error);
			}) 
		}
    }
	
	console.log('path->',path);
	let objElement = '';
	if(path){
		objElement = <OBJModel  width={520} height={420} position={{ x: 0, y: -2.5, z: -0.25 }} scale={{ x: 0.25, y: 0.25, z: 0.25 }} src={path} />;
	}
	//console.log('path->',path);
	return (
	<Fragment>
		{path ?
			<div id={'ans'+fieldId}>{objElement} <br /><b>Name:</b> {title}<br /><b>Description:</b> {description}<br /><b>Date:</b> {date}</div>
		: ''}
		<form className={classes.root +' upload-btn-type'} noValidate autoComplete="off">
			{ !showUploadButton && <div><Button id={"uploadbtn-"+fieldId} variant="contained" color="primary" onClick={() =>showUpload()}>{path ? 'Replace File' : 'Upload File'}</Button>&nbsp;{path ? <button id={"deletebtn-"+fieldId} type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={() =>deleteUpload()}><i className="fa fa-trash"></i></button> : null}</div>
			}
			{ !uploadSuccess && <Grid container spacing={3} className={classes.fileGrid}>
				<Grid item xs={12} className={classes.inputFile}>
					<Button
						variant="contained"
						component="label"
						color="secondary"
						className={classes.uploadButton}
						>
						<AddIcon /> Select a File
						<input
							type="file"
							name="file" 
							style={{ display: "none" }}
							onChange={ (e) => handleChange(e.target.files) }
						/>
					</Button>
					{ fileName && (<Box component="span" display="block">{fileName}</Box>) }
				</Grid>
				<Grid item xs={12}>
					<TextField id="title" label="Title" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)} />
				</Grid>
				<Grid item xs={12}>
					<TextField id="description" label="Description" variant="outlined" value={description} onChange={(e) => setDescription(e.target.value)} />
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" onClick={() =>uploadSubmit()}>Upload { showSpinner && <CircularProgress disableShrink size="20px" className={classes.spinner} /> }</Button>
				</Grid>
			</Grid> }
		</form>
	</Fragment>
	);
}
