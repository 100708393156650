import React, { Component } from 'react';
import $ from 'jquery';
import config from "../config";
import { gapi } from 'gapi-script';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, Table, TableSortLabel, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

export default class MongoDBMdsManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			mdsJsonFilesVersion:new Map(),
			filesExist:false,
			page:0,
			rowsPerPage:10,
			filterMode:'',
			mdsSheets:[],
		}

    }
	
	componentDidMount() {
		this.filterMdsForms();
		setInterval(this.checkFiles, 5000);
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		if(name == 'filterMode'){
			let that = this;
			setTimeout(function(){
				that.filterMdsForms();
			}, 1000);
		}
	}

	filterMdsForms=()=>{
		let mdsSheets = this.props.mdsSheets;
		let mdsForms = [];
		mdsSheets.map((sheet,i)=>{
			if(this.state.filterMode && this.state.filterMode != sheet.Mode){
				return false;
			}
			mdsForms.push(sheet);
		});
		//console.log('mdsForms->',mdsForms);
		this.setState({mdsSheets:mdsForms});
	}
		
	checkFiles=()=>{
		
		if(this.state.filesExist == false){
		   this.getMdsJsonFileVersion();
		}
	   
		if(this.props.mdsSheets){
		   this.setState({filesExist:true});
		}
	}
	
	getMdsJsonFileVersion=()=>{
		let mdsSheetGuids = [];
		
		this.props.mdsSheets.map((sheet,i)=>{
			mdsSheetGuids.push(sheet.MDSGuid);
		});
		
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'mds-json-version-v2';
		axios({
			method: 'POST',
			url: url,
			data: JSON.stringify(mdsSheetGuids),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			let files = response.data;
			if(files.length > 0){
				files.map((file,i)=>{
					if(file.Version){
						let mdsJsonFilesVersion = this.state.mdsJsonFilesVersion;
						mdsJsonFilesVersion.set(file.MDSGuid,file.Version);
						this.setState({mdsJsonFilesVersion});
					}
				});
			}
			
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	changeFileMode=(event)=>{
		let value = event.target.value;
		let guid = event.target.id;
		let mongoApiUrl = config.mongoApiUrl;
		let url = mongoApiUrl+'update-Mds-mode';
		let data = {
			"MDSGuid": guid,
			"Mode": value,
		}
		axios({
			method: 'POST',
			url: url,
			data: data,
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			if(value == 'Prod'){
				//this.uploadLayoutJsonMongoDb(sheetName);
				let that = this;
				that.props.getMdsSheets(that.props.selectedInstanceId);
				setTimeout(function(){
					that.filterMdsForms();
					that.getMdsJsonFileVersion();
				}, 5000);
			}
			this.setState({['mode-'+guid]:value});
			//console.log('response::', response);
		}).catch(error => {
			console.log('error::', error);
		})

	}
	
	handleChangePage = (event: unknown, newPage: number) => {
		this.setState({page:newPage});
	};

	handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage:event.target.value,page:0});
	};
	
	handleSort = column => {
		const { sortBy, sortOrder } = this.state;
		const isAsc = sortBy === column && sortOrder === 'asc';
		this.setState({
		  sortBy: column,
		  sortOrder: isAsc ? 'desc' : 'asc'
		});
	};
	
	render() {
		const {mdsJsonFilesVersion,layoutFriendlyName,selectedFiles,sortBy,sortOrder,mdsSheets} = this.state;
		let FieldsCount = 0;
		
		const StyledTableCell = styled(TableCell)(({ theme }) => ({
		  [`&.${tableCellClasses.head}`]: {
			//backgroundColor: '#e9e9e9',
			color: '#000;',
			padding: '20px 5px;',
			fontWeight: 600,
			fontSize: 15,
		  },
		  [`&.${tableCellClasses.body}`]: {
			fontSize: 16,
			backgroundColor: '#ffffff',
		  },
		}));
		
		const sortedData = [...mdsSheets].sort((a, b) => {
		  const isAsc = sortOrder === 'asc';
		  if (a[sortBy] < b[sortBy]) {
			return isAsc ? -1 : 1;
		  }
		  if (a[sortBy] > b[sortBy]) {
			return isAsc ? 1 : -1;
		  }
		  return 0;
		});
		
		return (
			<div className="mds-manager-mongo custom-container">
				
				<div className="mongo-header">
					<div className="mg-page-title"><h4>MongoDB MDS Manager</h4></div>
					<div className="wp-mode-filter">
						<FormControl sx={{ m: 1, minWidth: 155, maxWidth: 205 }} size="small">
							<InputLabel>Select Mode</InputLabel>
							<Select
								value={this.state.filterMode ? this.state.filterMode : ""}
								name={'filterMode'}
								onChange={this.getValue}
								input={<OutlinedInput label="Select Mode" />}
							>
							<MenuItem value={''} >Select Mode</MenuItem>
							<MenuItem value={'Build'} >Build</MenuItem>
							<MenuItem value={'Test'} >Test</MenuItem>
							<MenuItem value={'Dev-Prod'} >Dev-Prod</MenuItem> 
							<MenuItem value={'Prod'} >Prod</MenuItem>
							
							</Select>
						</FormControl>
					</div>
				</div>
				
				{mdsSheets ?
				<div className="mg-child-sheets">
				<TableContainer>
				  <Table size="small" aria-label="a dense table">
					<TableHead>
					  <TableRow>
						<StyledTableCell>
						<TableSortLabel
							active={sortBy === 'MDSName'}
							direction={sortOrder}
							onClick={() => this.handleSort('MDSName')}
						>
						MDS Name
						</TableSortLabel>
						</StyledTableCell>
						<StyledTableCell>
						<TableSortLabel
							active={sortBy === 'ModifiedDate'}
							direction={sortOrder}
							onClick={() => this.handleSort('ModifiedDate')}
						>
						Last Updated
						</TableSortLabel>
						</StyledTableCell>
						<StyledTableCell>Mode</StyledTableCell>
						<StyledTableCell>F2 Version</StyledTableCell>
						<StyledTableCell>Ver.#</StyledTableCell>
						<StyledTableCell>In PostGres</StyledTableCell>
						<StyledTableCell>PostGres Ver.#</StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					  {sortedData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((sheet) => {
						//console.log('mode-->',sheet.Mode);
						//console.log('filterMode-->',this.state.filterMode);
						/* if(this.state.filterMode && this.state.filterMode != sheet.Mode){
							return false;
						} */						
						
						let mode = sheet.Mode ? sheet.Mode : 'Test';
						let mongoVersion = mdsJsonFilesVersion.get(sheet.MDSGuid);
						//let friendlyName = layoutFriendlyName.get(sheetGuid);
						
						let classn = 'not-in-mongo';
						if(mongoVersion && (mongoVersion == sheet.Version || sheet.Version == '')){
							classn = 'has-in-mongo';
						}else{
							//return false;
						}
						
						let modeValue = sheet.Mode;
						
						let mState = this.state['mode-'+sheet.MDSGuid];
						if(mState){
							modeValue = mState;
						}
						
						FieldsCount = FieldsCount+sheet.FieldsCount;
						
						let lastUpdate = new Date(sheet.ModifiedDate).toLocaleString("en-US").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
						
						
						return (<TableRow key={sheet.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <StyledTableCell>{sheet.MDSName}</StyledTableCell>
						  <StyledTableCell>{lastUpdate}</StyledTableCell>
						  <StyledTableCell>
							<select id={sheet.MDSGuid} className="form-control" value={modeValue} onChange={this.changeFileMode}>
								<option value="Build">Build</option>
								<option value="Test">Test</option>
								<option value="Dev-Prod">Dev-Prod</option>
								<option value="Prod">Prod</option>
							</select>
						  </StyledTableCell>
						  <StyledTableCell><i className={"fa fa-circle "+classn} aria-hidden="true"></i></StyledTableCell>
						  <StyledTableCell><i>{sheet.Version && sheet.Version != 1 ? sheet.Version : '1.01' }</i></StyledTableCell>
						  <StyledTableCell><i className={"fa fa-circle not-in-mongo"} aria-hidden="true"></i></StyledTableCell>
						  <StyledTableCell><i>1.01</i></StyledTableCell>
						</TableRow>)
					  })}
					</TableBody>
				  </Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 20, 50, 100]}
					component="div"
					count={mdsSheets.length}
					rowsPerPage={this.state.rowsPerPage}
					page={this.state.page}
					onPageChange={this.handleChangePage}
					onRowsPerPageChange={this.handleChangeRowsPerPage}
				  />
				</div>
				:null}
			</div>
		);	
	}	
}
