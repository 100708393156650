import React,{ Component } from 'react';
import $ from 'jquery';
import { Form,Button } from 'react-bootstrap';

class MainHeader extends Component {
	constructor(props) {
        super(props);
	 	this.state ={

	 	}
    }
   	handleChange(event){
    	this.setState({renderComponent:event.target.value});
    	this.props.getModule(event.target.value);
    }
	
	logout = () => {
		let subdirectory = $('#subdirectory').val();
        //localStorage.removeItem('id');
        localStorage.removeItem('username');
		if(subdirectory){
			window.location = '/'+subdirectory+'/';
		}else{
			window.location = '/';
		}
    }
	
    render(){
        const { builderOption, dataMode} =this.props;
		let date = new Date();
		let newDate = ("0" + (date.getMonth() + 1)).slice(-2)+''+date.getDate()+''+date.getFullYear();
		let role = localStorage.getItem('role');
		let user_ip = localStorage.getItem('user_ip');
		return (
        <div className="card-header top-header">
            <div className="row">
				<div className="col-md-4 col-lg-6 col-3">
                    <h4>Builder 2.0</h4>
					<div className="instance-version">{'V1.08232024.a'}</div> 
                </div>
				<div className="col-md-3 col-lg-2 col-6">
				
					{role == 'Administrator' ? 
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="instance">Instance Manager</option>
							<option value="builder">{dataMode == 'TestForm' ? 'Test Mode' : 'Builder Mode'}</option>
							<option value="improve">Improve Mode</option>
							<option value="mds_mongo">MongoDB MDS Manager</option>
							<option value="layout_mongo">MongoDB Layout Manager</option>
							<option value="test_manager">Test & Review Manager</option>
							<option value="translation">Translation Manager</option>
							<option value="user">User Manager</option>
						</select>
					:null}
					
					{role == 'Builder' || role == 'MdsBuilder' || role == 'LayoutBuilder' ? 
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="builder">Builder Mode</option>
						</select>
					
					:null}
					
					{role == 'Tester' || role == 'NotesLetters' ? 
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="builder">Test Mode</option>
						</select>
					
					:null}
					
					{role == 'Reviewer' ? 
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="builder">Review Mode</option>
						</select>
					
					:null}
					
					{role == 'Improve' ? 
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="improve">Improve Mode</option>
							<option value="test_manager">Test & Review Manager</option>
						</select>
					
					:null}
					
					
				</div>
				{/* dataMode == 'TestForm' ?
				<div className="col-md-2">
					<select id="select-view-option" className="form-control" onChange={builderOption} >
						<option value="status_tracker">Status Tracker</option>
					</select> 
				</div>
				:
				<div className="col-md-2">
				
					{role == 'Administrator' ? 
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="instance">Instance Manager</option>
							<option value="builder">Builder Mode</option>
							<option value="mds_mongo">MongoDB MDS Manager</option>
							<option value="layout_mongo">MongoDB Layout Manager</option>
							<option value="user">User Manager</option>
						</select>
					:
						<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
							<option value="builder">Builder Mode</option>
						</select>
					}
					
					
					
				</div>
				 */}
            
                <div className="user-top-outer col-md-5 col-lg-4 col-3">
				{user_ip ? 
				<div className="user-ip">You are logged in from IP address: {user_ip}</div>
				:null}
                <div className="login-user-top">{'Welcome: '+localStorage.getItem('username')+'('+localStorage.getItem('role')+')'}</div> <a className="nav-link btn btn-outline-info sign-out-btn btn-sm" onClick={this.logout}>Sign Out</a>
				
                </div>
            </div>
        </div>            
    )}
	
}

export default MainHeader;